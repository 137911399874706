<template>
    <div class="reg_content">

        <div class="reg_panel">
            <router-link tag="div" to="/main"  style="width: 100%;text-align: right;padding: 5px;font-size: 22px;position: absolute;color: #fff9ff;cursor: pointer">
                <i class="fa fa-window-close"></i></router-link>
            <div class="crt_panel">
                <img src="../../assets/images/login/ptn/login-logo.gif" alt="" style="width: 100%">
            </div>
            <div class="form mt">
                <div class="fitem">
                    <label><i class="fa fa-user-circle"></i> User ID</label>
                    <input v-model="user.username" type="text" placeholder="4-15 character combination of letters and numbers">
                </div>
                <div class="fitem">
                    <label><i class="fa fa-user-circle"></i> Nickname</label>
                    <input v-model="user.nickname" type="text" placeholder="3-8 character combination of letters and numbersh">
                </div>
            </div>
            <div class="form mt">
                <div class="fitem">
                    <label><i class="fa fa-lock"></i> Password</label>
                    <input v-model="user.passwd" type="password" placeholder="4-20 characters English letters and numbers">
                </div>
                <div class="fitem">
                    <label><i class="fa fa-lock"></i> Verify password</label>
                    <input v-model="user.confirm_password" type="password" placeholder="verify password">
                </div>
            </div>


            <div class="form mt">
                <div class="fitem">
                    <label><i class="fa fa-user"></i> Bank name</label>
                    <input v-model="user.bank" type="text" placeholder="Bank name">
                </div>

                <div class="fitem">
                    <label><i class="fa fa-user"></i> Account name</label>
                    <input v-model="user.beneficiary" type="text" placeholder="Account name">
                </div>

            </div>
            <div class="form mt">
                <div class="fitem">
                    <label><i class="fa fa-id-card"></i> account number</label>
                    <input v-model="user.acNo" type="text" placeholder="Enter only numbers without `-`">
                </div>
                <div class="fitem">
                    <label><i class="fa fa-code"></i> Agent code</label>
                    <input type="text" v-model="user.code"  placeholder="Agent code">
                </div>
            </div>
            <div class="form mt">
                <div class="fitem">
                </div>
                <div class="fitem">
                    <label style="color: #182166">.</label>
                    <button @click="doRegister" class="btn-register">Sign up</button>
                </div>
            </div>
            <div class="err" v-show="erm != null">
                <i class="fa fa-warning"></i> {{erm}}
            </div>


        </div>

    </div>


</template>

<script>
    import {
        checkUserName,
        checkNickName,
        getVerificationCode,
        register,
        getSiteStatus, logout, getVerificationImgCode
    } from "../../network/userRequest";
    import {loginRegisterMixin} from "../../common/mixin";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import TopbarComp from "../../components/TopbarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import RightBarComp from "../../components/RightBarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import FootComp from "../../components/FootComp";

    export default {
        name: "register",
        components: {FootComp, RightBarBannerComp, RightBarComp, SportsLeftBarComp, LeftBarComp, TopbarComp},
        mixins: [loginRegisterMixin],
        data() {
            return {
                user: {
                    username: '',
                    passwd: '',
                    confirm_password: '',
                    nickname: '',
                    phone: '',
                    verificationCode: '',
                    bank: '',
                    beneficiary: '',
                    acNo: '',
                    extractPasswd: '',
                    code: '',
                    telcomp: null,
                    byear: null,
                    bmonth: null,
                    bday: null,
                },
                error: {
                    username: '',
                    passwd: '',
                    confirm_password: '',
                    nickname: '',
                    phone: '',
                    verificationCode: '',
                    bank: '',
                    beneficiary: '',
                    acNo: '',
                    extractPasswd: '',
                    code: ''
                },
                status: {
                    username: false,
                    passwd: false,
                    confirm_password: false,
                    nickname: false,
                    phone: false,
                    verificationCode: false,
                    bank: false,
                    beneficiary: false,
                    acNo: false,
                    extractPasswd: false,
                    code: false
                },
                isUsePhoneCode: false,
                erm: null,
                syear: [],
                smonth: [],
                sday: [],
            }
        },
        methods: {
            gologin(){

            },
            getVerifyPhoneCode() {
                if (this.checkPhone()) {
                    this.$store.commit(RECEIVE_SHOW_LOADING)
                    getVerificationCode(this.user.phone).then(res => {
                        this.$store.commit(RECEIVE_HIDE_LOADING)
                        if (res.data.success) {
                            this.$swal({
                                title: 'A mobile phone verification code has been sent. Please check your cell phone text message',
                                type: 'success',
                                background: '#ffffff',
                                showCancelButton: false,
                                showConfirmButton: true
                            })
                        } else {
                            this.erm = res.data.msg
                        }

                    })
                }
            },
            checkUserName() { //회원가입시 아이디 체크
                if (!this.testRegxUserName) {
                    this.error.username = '아이디는 4-15자리 영문,숫자조합입니다'
                    this.status.username = false;
                    return false;
                }
                this.error.username = '';
                return true;
                /*//아이디 사용가능 여부 체크
                this.$store.commit(RECEIVE_SHOW_LOADING)
                checkUserName(this.user.username).then(res => {
                    if (res.data.success) {
                        this.error.username = ''
                        this.status.username = true;
                    } else {
                        this.error.username = res.data.msg
                        this.status.username = false;
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })*/
            },
            checkNickName() { //회원가입시 닉네임 체크
                if (!this.testRegxNickname) {
                    this.error.nickname = '닉네임은 2-6자리 한글만 가능합니다'
                    this.status.nickname = false;
                    return false;
                }
                this.error.nickname = '';
                return true;
                //아이디 사용가능 여부 체크
                /*this.$store.commit(RECEIVE_SHOW_LOADING)
                checkNickName(this.user.nickname).then(res => {
                    if (res.data.success) {
                        this.error.nickname = ''
                        this.status.nickname = true;
                    } else {
                        this.error.nickname = res.data.msg
                        this.status.nickname = false;
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })*/
            },
            checkPasswd() {
                if (!this.testRegxPasswd) {
                    this.error.passwd = '비밀번호는 4-20자리 입니다';
                    this.status.passwd = false;
                    return false;
                }
                this.error.passwd = '';
                this.status.passwd = true;
                return true;
            },
            checkConfirmPassword() {
                if (this.user.confirm_password.trim() === '' || this.user.passwd.trim() !== this.user.confirm_password.trim()) {
                    this.error.confirm_password = '입력하신 비밀번호가 동일하지 않습니다'
                    this.status.confirm_password = false;
                    return false;
                }
                this.error.confirm_password = '';
                this.status.confirm_password = true;
                return true;

            },
            checkPhone() {
                if (!/^[0-9]{11,12}$/.test(this.user.phone)) {
                    this.error.phone = '폰번호 오류입니다'
                    this.status.phone = false;
                    return false;
                }
                this.error.phone = '';
                this.status.phone = true;
                return true;

            },
            checkBank() {
                if (this.user.bank === '') {
                    this.error.bank = '은행명을 선택하세요'
                    this.status.bank = false;
                    return false;
                }
                this.error.bank = ''
                this.status.bank = true;
                return true;
            },
            checkBeneficiary() {
                if (!/^.{1,10}$/.test(this.user.beneficiary)) {
                    this.error.beneficiary = '예금주를 입력하세요'
                    this.status.beneficiary = false;
                    return false;
                }
                this.error.beneficiary = ''
                this.status.beneficiary = true;
                return true;

            },
            checkAcNo() {
                if (!/^[0-9]{5,20}$/.test(this.user.acNo)) {
                    this.error.acNo = '계좌번호 오류입니다.\'-\'없이 숫자로만 입력하세요'
                    this.status.acNo = false;
                    return false;
                }
                this.error.acNo = '';
                this.status.acNo = true;
                return true;

            },
            checkCode() {
                if (!/^[0-9a-zA-Z]{2,50}$/.test(this.user.code)) {
                    this.error.code = '추천인 코드를 정확하게 입력하세요';
                    this.status.code = false;
                    return false;
                }
                this.error.code = '';
                this.status.code = true;
                return true;
            },
            doRegister() {
                if (!this.checkConfirmPassword()) {
                    // this.$swal({
                    //     title: '입력하신 비밀번호가 동일하지 않습니다',
                    //     type: 'error',
                    //     showCancelButton: false,
                    //     showConfirmButton: true
                    // })
                    //this.erm = '입력하신 비밀번호가 동일하지 않습니다'
                    this.erm = 'The password you entered is not the same'
                    return false;
                }
                this.$store.commit(RECEIVE_SHOW_LOADING)
                register(this.user).then(res => {
                    if (res.data.success) {
                        this.$swal({
                            //title: 'Congratulations on signing up. You can use it after confirmation from the administrator.',
                            title: '회원가입을 축하드립니다.관리자 확인후 이용가능합니다',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        this.erm = null
                        this.$router.replace('/login')
                    } else {
                        // this.$swal({
                        //     title: res.data.msg,
                        //     type: 'error',
                        //     showCancelButton: false,
                        //     showConfirmButton: true
                        // })
                        this.erm = res.data.msg
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })
            },
            getVerifyImgCode() {
                getVerificationImgCode().then(res => {
                    if (res.data.success) {
                    } else {
                        this.erm = res.data.msg
                    }

                })
            },
        },
        created() {
            /*getSiteStatus().then(res=>{
                if(!res.data.success){
                    this.$router.push("/login")
                }
            })*/
            let b = 1949
            for (let i = 1; i <= 71; i++) {
                let bb = b + i;
                this.syear.push(bb)
            }
            for (let i = 1; i <= 12; i++) {
                this.smonth.push(i)
            }
            for (let i = 1; i <= 31; i++) {
                this.sday.push(i)
            }

        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");

    .reg_content {
        width: 100%;
        height: 100vh;
        position: fixed;
        background: url("../../assets/images/login/ptn/login-bg.jpg") no-repeat center center;
    }

    .reg_panel {
        width: 522px;
        height: 400px;
        position: absolute;
        left: 50%;
        top: 50%;
        background-size: cover;
        -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
        transform: translateX(-50%) translateY(-50%) scale(1);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background: rgba(10, 10, 10, .8);
        border: 0px solid #00b3d1;
        border-image: linear-gradient(to right, #00b3d1, #00b3d1) 1;
        box-shadow: 0 0 10px #00b3d1;
    }
    .crt_panel {
        width: 265px;
        position: absolute;
        left: 50%;
        top: -120px;
        -webkit-transform: translateX(-50%)scale(1);
        transform: translateX(-50%)scale(1);
        z-index: 0;
    }

    .form {
        width: 100%;
        height: 40px;
        padding: 0 5px 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
    }

    .form .fitem {
        width: 49.5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-top: 15px;
    }

    .fitem label {
        padding: 5px 0;
        font-size: 14px;
        color: #cfd1d0;
    }

    .fitem input {
        width: 95%;
        text-align: center;
        height: 32px;
        line-height: 32px;
        color: #d0d0d0;
        background-color: #333335;
        border: 1px #1e1e1e solid;
        border-radius:5px;
    }

    .mt {
        margin-top: 25px;
    }

    .err {
        color: #ff4d4d;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        margin-top: 40px;
        animation: sansan 700ms infinite;
        -webkit-animation: sansan 700ms infinite;
    }


    .btn-register {
        height: 32px;
        background: linear-gradient(180deg, #d0b059, #eecd75);
        color: #353535;
        width: 95%;
        font-size: 14px;
        font-weight: bold;
        border-radius: 2px;
        border: 1px solid #313b45
    }

    @media screen and (max-width: 1024px) {
        .reg_panel {
            width: 375px !important;
            height: 400px !important;
            background-size: 100% !important;
            transform: translateX(-50%) translateY(-45%) scale(1) !important;
        }
        .crt_panel {
            top: -130px!important;
        }
    }

    @keyframes sansan {
        from {
            opacity: 1.0;
        }
        70% {
            opacity: 0.8;
        }
        50% {
            opacity: 0.6;
        }
        to {
            opacity: 1.0;
        }
    }

    @-webkit-keyframes sansan {
        from {
            opacity: 1.0;
        }
        50% {
            opacity: 0.4;
        }
        to {
            opacity: 1.0;
        }
    }
</style>
